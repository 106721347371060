.container {
  background-color: #ffffff;
}
.mainContainer {
  max-width: 1440px;
  height: 100vh;
  margin: auto;
  display: grid;
  grid-template-columns: 50% 50%;
}
.leftInsideWrapperStyle {
  width: 100%;
  padding: 120px 135px 0 135px;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.imageDisplayStyle {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.titleOutsideWrapper {
  padding: 47px 85px;
}
.titleImgWrapperStyle {
  width: 100px;
  height: 33px;
}
.titleWrapperStyle {
  max-width: 391px;
}
.titleStyle {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 50px;
  line-height: 80px;
  color: #333333;
  text-align: center;
}
.descriptionWrapperStyle {
  max-width: 435px;
}
.descriptionStyle {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #828282;
  margin-top: 25px;
  margin-bottom: 30px;
  text-align: center;
}
.inputWrapperStyle {
  max-width: 345px;
  margin-top: 24px;
}
.inputFieldStyle {
  width: 100%;
  height: 64px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  font-family: "Poppins-Regular", sans-serif;
}
.inputLabelStyles {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.buttonStyle {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 16px;
  line-height: 24px;
  background-color: #eb5757;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  width: 100%;
  height: 64px;
}
.socialContainer {
  width: 385px;
  margin: auto;
}
.socialIconsWrapperStyle {
  margin-top: 250px;
  display: flex;
  gap: 24px;
}
.textSocialStyle {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.textCopyRightsStyle {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  margin-top: 20px;
}
.socialImgWrapperStyle {
  width: 24px;
  height: 24px;
}

.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1401px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1206px) {
}

@media screen and (max-width: 1080px) {
  .mainContainer {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .leftInsideWrapperStyle {
    width: 100%;
    padding: 64px 80px 64px 80px;
  }
  .titleOutsideWrapper {
    padding: 47px 85px;
  }
  .titleImgWrapperStyle {
    width: 100px;
    height: 33px;
  }
  .titleWrapperStyle {
    max-width: 391px;
  }
  .titleStyle {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 40px;
    line-height: 60px;
    color: #333333;
  }
  .descriptionWrapperStyle {
    max-width: 435px;
    margin-top: 25px;
  }
  .descriptionStyle {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #828282;
  }
  .inputWrapperStyle {
    max-width: 345px;
    height: 64px;
    margin-top: 24px;
  }
  .inputFieldStyle {
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    font-family: "Poppins-Regular", sans-serif;
  }
  .buttonStyle {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 16px;
    line-height: 24px;
    background-color: #eb5757;
    border-radius: 8px;
    border: none;
    width: 100%;
    padding: 24px 0;
  }
  .socialContainer {
    width: 300px;
    margin: auto;
  }
  .socialIconsWrapperStyle {
    margin-top: 250px;
    display: flex;
    gap: 24px;
  }
  .textSocialStyle {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
  }
  .textCopyRightsStyle {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #828282;
    margin-top: 20px;
  }
  .socialImgWrapperStyle {
    width: 24px;
    height: 24px;
  }

  .imgStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 896px) {
  .container {
    background-color: #ffffff;
  }
  .mainContainer {
    max-width: 1440px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .leftInsideWrapperStyle {
    width: 100%;
    padding: 32px 64px 32px 64px;
  }
  .titleOutsideWrapper {
    padding: 42px 64px;
  }
  .titleImgWrapperStyle {
    width: 100px;
    height: 33px;
  }
  .titleWrapperStyle {
    max-width: 391px;
  }
  .titleStyle {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 40px;
    line-height: 60px;
    color: #333333;
  }
  .descriptionWrapperStyle {
    max-width: 435px;
    margin-top: 25px;
  }
  .descriptionStyle {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #828282;
  }
  .inputWrapperStyle {
    max-width: 345px;
    height: 64px;
    margin-top: 24px;
  }
  .inputFieldStyle {
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    font-family: "Poppins-Regular", sans-serif;
  }
  .buttonStyle {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 16px;
    line-height: 24px;
    background-color: #eb5757;
    border-radius: 8px;
    border: none;
    width: 100%;
    padding: 24px 0;
  }
  .socialContainer {
    width: 385px;
    margin: auto;
  }
  .socialIconsWrapperStyle {
    margin-top: 160px;
    display: flex;
    gap: 24px;
  }
  .textSocialStyle {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
  }
  .textCopyRightsStyle {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #828282;
    margin-top: 20px;
  }
  .socialImgWrapperStyle {
    width: 24px;
    height: 24px;
  }

  .imgStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 668px) {
  .mainContainer {
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
  }
  .imageDisplayStyle {
    width: 100%;
  }
  .leftInsideWrapperStyle {
    width: 100%;
    padding: 24px;
  }
  .titleOutsideWrapper {
    padding: 43px 24px;
  }
  .titleImgWrapperStyle {
    width: 100px;
    height: 33px;
  }
  .titleWrapperStyle {
    max-width: 391px;
  }
  .titleStyle {
    font-size: 32px;
    line-height: 48px;
  }
  .descriptionWrapperStyle {
    max-width: 335px;
    margin-top: 25px;
  }
  .descriptionStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .inputWrapperStyle {
    max-width: 345px;
    height: 64px;
    margin-top: 24px;
  }

  .socialContainer {
    width: 100%;
    margin: auto;
  }
  .socialIconsWrapperStyle {
    margin-top: 217px;
    gap: 20px;
  }

  .textSocialStyle {
    font-size: 10px;
    line-height: 16px;
  }
  .textCopyRightsStyle {
    font-size: 10px;
    line-height: 16px;
    margin-top: 20px;
  }

  .socialImgWrapperStyle {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 468px) {
}
